var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState } from "react";
var drawerWidth = 300;
var scrollWidth = 16;
var useStyles = makeStyles(function (theme) {
    var _a, _b, _c;
    return {
        appBar: (_a = {},
            _a[theme.breakpoints.up("sm")] = {
                width: "calc(100% - " + drawerWidth + "px)",
                marginLeft: drawerWidth,
                marginRight: scrollWidth,
            },
            _a),
        appBarSpacer: theme.mixins.toolbar,
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
        },
        content: {
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
        },
        drawer: (_b = {},
            _b[theme.breakpoints.up("sm")] = {
                width: drawerWidth,
                flexShrink: 0,
            },
            _b),
        drawerPaper: {
            width: drawerWidth,
        },
        menuButton: (_c = {
                marginRight: theme.spacing(2)
            },
            _c[theme.breakpoints.up("sm")] = {
                display: "none",
            },
            _c),
        root: {
            display: "flex",
        },
        title: {
            flexGrow: 1,
        },
        toolbar: theme.mixins.toolbar,
        toolbarIcon: __assign({ display: "flex", alignItems: "center", justifyContent: "flex-end", padding: "0 8px" }, theme.mixins.toolbar),
    };
});
var ResponsiveDrawer = function (props) {
    var classes = useStyles();
    return (_jsxs("nav", __assign({ className: classes.drawer }, { children: [_jsx(Hidden, __assign({ smUp: true, implementation: "css" }, { children: _jsxs(Drawer, __assign({ variant: "temporary", open: props.mobileOpen, onClose: props.handleDrawerToggle, classes: {
                        paper: classes.drawerPaper,
                    }, ModalProps: {
                        keepMounted: true, // Better open performance on mobile.
                    } }, { children: [_jsx("div", __assign({ className: classes.toolbarIcon }, { children: _jsx(IconButton, __assign({ onClick: props.handleDrawerToggle }, { children: _jsx(ChevronLeftIcon, {}, void 0) }), void 0) }), void 0),
                        _jsx(_Fragment, { children: props.children }, void 0)] }), void 0) }), void 0),
            _jsx(Hidden, __assign({ xsDown: true, implementation: "css" }, { children: _jsx(Drawer, __assign({ classes: {
                        paper: classes.drawerPaper,
                    }, variant: "permanent", open: true }, { children: props.children }), void 0) }), void 0)] }), void 0));
};
var Footer = function () {
    return (_jsx(Typography, __assign({ variant: "body2", color: "textSecondary", align: "center" }, { children: _jsx("a", __assign({ href: "//tahiti.ai" }, { children: "Tahiti.ai" }), void 0) }), void 0));
};
var CurateLayout = function (props) {
    var classes = useStyles();
    var _a = useState(false), mobileOpen = _a[0], setMobileOpen = _a[1];
    var handleDrawerToggle = function () {
        setMobileOpen(!mobileOpen);
    };
    return (_jsxs("div", __assign({ className: classes.root }, { children: [_jsx(CssBaseline, {}, void 0),
            _jsx(AppBar, __assign({ position: "fixed", className: classes.appBar + " padding-below-gdpr-popup" }, { children: _jsxs(Toolbar, __assign({ className: classes.toolbar }, { children: [_jsx(IconButton, __assign({ "aria-label": "open drawer", color: "inherit", edge: "start", onClick: handleDrawerToggle, className: classes.menuButton }, { children: _jsx(MenuIcon, {}, void 0) }), void 0), Array.isArray(props.children) && props.children[1]] }), void 0) }), void 0),
            _jsx(ResponsiveDrawer, __assign({ mobileOpen: mobileOpen, handleDrawerToggle: handleDrawerToggle }, { children: _jsx("div", __assign({ className: "padding-below-gdpr-popup" }, { children: Array.isArray(props.children) && props.children[0] }), void 0) }), void 0),
            _jsxs("main", __assign({ className: classes.content + " padding-below-gdpr-popup" }, { children: [_jsx("div", { className: classes.appBarSpacer }, void 0),
                    _jsx(Container, __assign({ maxWidth: "lg", className: classes.container }, { children: _jsx(_Fragment, { children: Array.isArray(props.children) && props.children[2] }, void 0) }), void 0),
                    _jsx(Footer, {}, void 0)] }), void 0)] }), void 0));
};
export { CurateLayout };
