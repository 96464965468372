var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import axios from "axios";
import React, { useState } from "react";
import { AssetCategories } from "../../../../pkgs/src/components/AssetCategories";
import { AssetImages } from "../../../../pkgs/src/components/AssetImages";
import { AssetMeta } from "../../../../pkgs/src/components/AssetMeta";
import { AssetSummary } from "../../../../pkgs/src/components/AssetSummary";
import { AssetTags } from "../../../../pkgs/src/components/AssetTags";
import { AssetCollectionWrapped } from "../../../../pkgs/src/components/CurateRelated/AssetCollectionWrapped";
import { CurateLayout } from "../../../../pkgs/src/components/CurateRelated/CurateLayout";
import { DummyDetails } from "../../../../pkgs/src/components/CurateRelated/DummyDetails";
import { FolderTree } from "../../../../pkgs/src/components/CurateRelated/FolderTree";
import { SidebarItems } from "../../../../pkgs/src/components/CurateRelated/SidebarItems";
import { modifyAssetConfig } from "../../../../pkgs/src/core/libraryService";
var a11yProps = function (index) {
    return {
        id: "scrollable-auto-tab-" + index,
        "aria-controls": "scrollable-auto-tabpanel-" + index,
    };
};
var TabPanel = function (props) {
    return (_jsx("div", __assign({ role: "tabpanel", hidden: props.value !== props.index, id: "scrollable-auto-tabpanel-" + props.index, "aria-labelledby": "scrollable-auto-tab-" + props.index }, { children: props.value === props.index && _jsx(Box, __assign({ p: 3 }, { children: props.children }), void 0) }), void 0));
};
var CuratePage = function (props) {
    var _a = useState(0), tabValue = _a[0], setTabValue = _a[1];
    var _b = useState([]), selectedHandles = _b[0], setSelectedHandles = _b[1];
    var _c = useState({}), products = _c[0], setProducts = _c[1];
    var modifyProduct = function (product, newProduct) {
        //
        // Optimistically record the change
        //
        var newProducts = __assign({}, products);
        newProducts[newProduct.handle] = newProduct;
        setProducts(newProducts);
        //
        // Propose the change to the server
        //
        var config = modifyAssetConfig(product, newProduct);
        axios(config)
            .then(function (result) {
            if (result.data !== product.handle) {
                //
                // server rejected the change
                //
                newProducts[newProduct.handle] = product;
                setProducts(newProducts);
                // TODO report the rejection to the user
                console.log("server rejected change to " + product.handle);
            }
        })
            .catch(function (reason) {
            console.log(reason);
        });
    };
    var handleTabChange = function (event, newValue) {
        setTabValue(newValue);
    };
    return (_jsxs(CurateLayout, __assign({}, props, { title: "Dashboard" }, { children: [_jsxs(_Fragment, { children: [_jsx(Divider, {}, void 0),
                    _jsx(SidebarItems, {}, void 0),
                    _jsx(Divider, {}, void 0),
                    _jsx(FolderTree, { products: products, selectedHandles: selectedHandles, setProducts: setProducts, setSelectedHandles: setSelectedHandles }, void 0)] }, void 0),
            _jsxs(Tabs, __assign({ value: tabValue, onChange: handleTabChange, indicatorColor: "secondary", textColor: "secondary", variant: "scrollable", scrollButtons: "auto", "aria-label": "scrollable auto tabs example" }, { children: [_jsx(Tab, __assign({ label: "Summary" }, a11yProps(0)), void 0),
                    _jsx(Tab, __assign({ label: "Thumbs" }, a11yProps(1)), void 0),
                    _jsx(Tab, __assign({ label: "Categories" }, a11yProps(2)), void 0),
                    _jsx(Tab, __assign({ label: "Tags" }, a11yProps(3)), void 0),
                    _jsx(Tab, __assign({ label: "Meta" }, a11yProps(4)), void 0),
                    _jsx(Tab, __assign({ label: "Attributes" }, a11yProps(5)), void 0)] }), void 0),
            _jsxs(_Fragment, { children: [_jsx(TabPanel, __assign({ value: tabValue, index: 0 }, { children: _jsx(AssetCollectionWrapped, { products: products, selectedHandles: selectedHandles, modifyProduct: modifyProduct, component: AssetSummary }, void 0) }), void 0),
                    _jsx(TabPanel, __assign({ value: tabValue, index: 1 }, { children: _jsx(AssetCollectionWrapped, { products: products, selectedHandles: selectedHandles, modifyProduct: modifyProduct, component: AssetImages }, void 0) }), void 0),
                    _jsx(TabPanel, __assign({ value: tabValue, index: 2 }, { children: _jsx(AssetCollectionWrapped, { products: products, selectedHandles: selectedHandles, modifyProduct: modifyProduct, component: AssetCategories }, void 0) }), void 0),
                    _jsx(TabPanel, __assign({ value: tabValue, index: 3 }, { children: _jsx(AssetCollectionWrapped, { products: products, selectedHandles: selectedHandles, modifyProduct: modifyProduct, component: AssetTags }, void 0) }), void 0),
                    _jsx(TabPanel, __assign({ value: tabValue, index: 4 }, { children: _jsx(AssetCollectionWrapped, { products: products, selectedHandles: selectedHandles, modifyProduct: modifyProduct, component: AssetMeta }, void 0) }), void 0),
                    _jsx(TabPanel, __assign({ value: tabValue, index: 5 }, { children: _jsx(DummyDetails, { all: false }, void 0) }), void 0)] }, void 0)] }), void 0));
};
export { CuratePage };
