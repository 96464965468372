var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Suspense } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { NetworkErrorBoundary } from "rest-hooks";
import { DetailsArea } from "../../../../pkgs/src/components/SearchRelated/Details/DetailsArea";
import { DetailsHeader } from "../../../../pkgs/src/components/SearchRelated/Details/DetailsHeader";
var DetailsPage = function (props) {
    var mangledHandle = props.handle ? props.handle : "";
    return (_jsxs(_Fragment, { children: [_jsx(DetailsHeader, { handle: mangledHandle, searchResults: props.searchResults }, void 0),
            _jsx(Suspense, __assign({ fallback: _jsx(ScaleLoader, {}, void 0) }, { children: _jsx(NetworkErrorBoundary, { children: _jsx(DetailsArea, { handle: mangledHandle }, void 0) }, void 0) }), void 0)] }, void 0));
};
export { DetailsPage };
