var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./config";
import "@webcomponents/custom-elements"; // required by model-viewer on older browsers
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import React, { useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { CacheProvider } from "rest-hooks";
import { PostLoginHandler } from "../../pkgs/src/components/PostLoginHandler";
import { UserProvider } from "../../pkgs/src/components/UserProvider";
import { reduxStore } from "../../pkgs/src/core/store";
import { kSearchResultsZero } from "../../pkgs/src/core/types";
import { CuratePage } from "./pages/CuratePage";
import { DetailsPage } from "./pages/DetailsPage";
import { Error404 } from "./pages/Error404";
import { SearchPage } from "./pages/SearchPage";
var theme = createMuiTheme({
    palette: {
        primary: {
            main: "#0948b3",
        },
        secondary: {
            main: "#a0e212",
        },
    },
    overrides: {
        MuiTab: {
            textColorSecondary: {
                color: "lightgray",
                "&:hover": {
                    color: "white",
                },
                // "&.Mui-selected": {
                //     color: "white",
                // }
            },
        },
    },
});
var App = function () {
    var _a = useState(""), search = _a[0], setSearch = _a[1];
    var _b = useState(kSearchResultsZero), searchResults = _b[0], setSearchResults = _b[1];
    // Consider wrapping in <React.StrictMode>
    return (_jsx(Provider, __assign({ store: /* eslint-disable-line */ reduxStore }, { children: _jsx(UserProvider, { children: _jsx(CacheProvider, { children: _jsx(ThemeProvider, __assign({ theme: theme }, { children: _jsx(BrowserRouter, { children: _jsxs(Switch, { children: [_jsx(Route, { path: "/postlogin", exact: true, render: function (props) { return (_jsx(PostLoginHandler, __assign({}, props), void 0)); } }, void 0),
                                _jsx(Route, { path: "/", exact: true, render: function (props) { return (_jsx(SearchPage, __assign({}, props, { search: search, setSearch: setSearch, searchResults: searchResults, setSearchResults: setSearchResults }), void 0)); } }, void 0),
                                _jsx(Route, { path: "/id/:handle", render: function (props) { return (_jsx(DetailsPage, __assign({}, props, { handle: props.match.params.handle, searchResults: searchResults }), void 0)); } }, void 0),
                                _jsx(Route, { path: "/curate", render: function (props) { return (_jsx(CuratePage, __assign({}, props), void 0)); } }, void 0),
                                _jsx(Route, { path: "/404", component: Error404 }, void 0),
                                _jsx(Route, { component: Error404 }, void 0)] }, void 0) }, void 0) }), void 0) }, void 0) }, void 0) }), void 0));
};
export { App };
