var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AssetCollection } from "../../AssetCollection";
import { storageListeners, useReduxUserPrefs, } from "../../../core/store";
var AssetCollectionWrapped = function (props) {
    var userPrefsSelector = useReduxUserPrefs[0], userPrefsActionsFactory = useReduxUserPrefs[1], userPrefsStorageKey = useReduxUserPrefs[2];
    var userPrefsDispatch = useDispatch();
    var userPrefsActions = userPrefsActionsFactory(userPrefsDispatch);
    var userPrefs = useSelector(function (state) {
        return userPrefsSelector(state);
    });
    if (userPrefsStorageKey) {
        storageListeners.set(userPrefsStorageKey, userPrefsActions.assign);
    }
    return _jsx(AssetCollection, __assign({}, props, { userPrefs: userPrefs }), void 0);
};
export { AssetCollectionWrapped };
