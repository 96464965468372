var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classnames from "classnames";
import React from "react";
import { Hints } from "../../../SearchInput/Hints";
import { kAttrSourcePath, kFileFullFbx, kFilePreviewFbx, } from "../../../../core/types";
import styles from "./styles.module.scss";
var DetailsSummary = function (props) {
    var dummyClick = function () { };
    var fbxUrl = props.product.files[kFileFullFbx] &&
        props.product.files[kFileFullFbx].length > 0
        ? props.product.files[kFileFullFbx]
        : props.product.files[kFilePreviewFbx] &&
            props.product.files[kFilePreviewFbx].length > 0
            ? props.product.files[kFilePreviewFbx]
            : "";
    return (_jsxs("div", __assign({ className: classnames(props.className, styles.outer), style: props.style }, { children: [_jsx("h1", { children: props.product.title }, void 0),
            _jsx("p", { children: props.product.handle }, void 0),
            _jsx("p", { children: props.product.attr[kAttrSourcePath] }, void 0),
            _jsxs("div", __assign({ className: styles.pdButtons }, { children: [_jsx("button", __assign({ onClick: function () { return alert("Requires desktop app"); } }, { children: "Live Add" }), void 0),
                    fbxUrl && (_jsx("a", __assign({ href: fbxUrl, download: true }, { children: "Download FBX" }), void 0))] }), void 0),
            _jsx(Hints, { hints: props.product.tags, removable: false, onClick: dummyClick }, void 0)] }), void 0));
};
export { DetailsSummary };
