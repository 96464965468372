var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React, { Suspense, useEffect, useState } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { NetworkErrorBoundary, useFetcher } from "rest-hooks";
import { AssetThumbnail } from "../../../AssetThumbnail";
import { kSearchResultsZero, } from "../../../../core/types";
import { SearchResource } from "../../../../core/searchSdk/SearchResource";
import { SearchFooter } from "../SearchFooter";
var useStyles = makeStyles(function (theme) { return ({
    outer: {
        marginTop: "30px",
        marginLeft: "20px",
        marginRight: "20px",
    },
    gridContainer: {
        width: "100%",
        height: "100%",
        transform: "translateZ(0)",
    },
    noticeText: {
        marginTop: "50px",
        textAlign: "center",
    },
}); });
var kMaxResults = 216;
var kRestartToken = "restart-search";
var kDefaultSearch = "qqz";
var kLimit = 50;
var SearchResults = function (props) {
    var classes = useStyles();
    var _a = useState(kSearchResultsZero), intermediateResults = _a[0], setIntermediateResults = _a[1];
    var getMoreResults = useFetcher(SearchResource.listShape());
    // BEGIN Destructure props for use as dependencies for useEffects
    var search = props.search, searchResults = props.searchResults, setSearchResults = props.setSearchResults;
    // END Destructure props for use as dependencies for useEffects
    useEffect(function () {
        //
        // PURPOSE: When the search string changes, request the first page of
        // results from the search engine
        //
        var mangledSearchQuery = search ? search : kDefaultSearch;
        getMoreResults({
            query: mangledSearchQuery,
            limit: Math.min(kLimit, kMaxResults),
            offset: 0,
        })
            .catch(function (reason) { return console.log(reason); })
            .then(function (data) {
            setIntermediateResults(data);
        }, function () { });
    }, [getMoreResults, search, setSearchResults]);
    useEffect(function () {
        //
        // PURPOSE: When new searchResults are received for display,
        // if needed request the next page of results
        //
        if (searchResults.query === "") {
            // ignore initialization step
            return;
        }
        //
        // This effect has a dependency on search, but if the search field changes
        // the effect wont fire because in the next lines we check whether
        // the possibly updated searchResults have the correct query to match the current search.
        // Right after search changes, the old searchResults wont match, so this effect
        // wont seek additional results from the server until the other useEffect has
        // requested and received the first batch of search results.
        //
        var mangledSearchQuery = search ? search : kDefaultSearch;
        if (mangledSearchQuery !== searchResults.query &&
            searchResults.query !== kRestartToken) {
            // ignore when search string changes, wait for result to come in that match search string
            return;
        }
        var usableResults = searchResults.query === mangledSearchQuery
            ? searchResults
            : kSearchResultsZero;
        var wantAdditionalResults = usableResults.hits.length < kMaxResults;
        if (wantAdditionalResults) {
            getMoreResults({
                query: mangledSearchQuery,
                limit: Math.min(kLimit, kMaxResults),
                offset: usableResults.hits.length,
            })
                .catch(function (reason) { return console.log(reason); })
                .then(function (data) {
                setIntermediateResults(data);
            }, function () { });
        }
    }, [getMoreResults, search, searchResults, setIntermediateResults]);
    useEffect(function () {
        //
        // PURPOSE: When results are received asynchronously from the search engine,
        // check if they match the previously received data and add to the searchResults
        // (possibly wiping out old non-matching results).
        //
        var data = intermediateResults;
        if (data === undefined || data.query === "") {
            // ignore initialization
            return;
        }
        var needToUpdateStoredSearchResults = data.hits.length > 0 ||
            (data.hits.length === 0 &&
                (data.offset === 0 || data.query === kRestartToken));
        if (needToUpdateStoredSearchResults) {
            var previousSearchResults = data.offset === 0
                ? kSearchResultsZero
                : data.query === searchResults.query
                    ? searchResults
                    : kSearchResultsZero;
            if (previousSearchResults.hits.length !== data.offset) {
                console.log("encountered race condition");
                // encountered complex race condition - reset things
                setSearchResults(__assign(__assign({}, kSearchResultsZero), { query: kRestartToken }));
            }
            else {
                var combinedResults = {
                    hits: __spreadArray(__spreadArray([], previousSearchResults.hits), data.hits),
                    offset: previousSearchResults.offset,
                    limit: data.limit,
                    query: previousSearchResults.query
                        ? previousSearchResults.query
                        : data.query,
                };
                setSearchResults(combinedResults);
            }
        }
    }, [intermediateResults, /* searchResults, */ setSearchResults]); // eslint-disable-line react-hooks/exhaustive-deps
    var getProductDetailsUrl = function (handle) {
        return "/id/" + handle;
    };
    return (_jsx(Suspense, __assign({ fallback: _jsx(ScaleLoader, {}, void 0) }, { children: _jsxs(NetworkErrorBoundary, { children: [_jsx("div", __assign({ className: classes.outer }, { children: _jsxs(Grid, __assign({ container: true, spacing: 1, className: classes.gridContainer }, { children: [props.searchResults.query !== "" &&
                                props.searchResults.query !== kRestartToken &&
                                props.searchResults.hits.length === 0 && (_jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx("p", __assign({ className: classes.noticeText }, { children: "Not Found" }), void 0) }), void 0)),
                            props.searchResults.hits.map(function (product) {
                                return (_jsx(Grid, __assign({ item: true, sm: 2 }, { children: _jsx(AssetThumbnail, { product: product, linkTo: getProductDetailsUrl(product.handle) }, void 0) }), product.handle));
                            }),
                            props.searchResults.hits &&
                                props.searchResults.hits.length >= kMaxResults && (_jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx("p", __assign({ className: classes.noticeText }, { children: "Refine your search to see more ..." }), void 0) }), void 0))] }), void 0) }), void 0),
                props.searchResults.hits.length > 0 && _jsx(SearchFooter, {}, void 0)] }, void 0) }), void 0));
};
export { SearchResults };
