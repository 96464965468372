var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TreeItem from "@material-ui/lab/TreeItem";
import React from "react";
import { useGetFolderApi } from "../../../../core/libraryService";
import { IfExpanded } from "../IfExpanded";
var useTreeItemStyles = makeStyles(function (theme) { return ({
    root: {
        color: theme.palette.text.secondary,
        "&:hover > $content": {
            backgroundColor: theme.palette.action.hover,
        },
        "&:focus > $content, &$selected > $content": {
            backgroundColor: "var(--tree-view-bg-color, " + theme.palette.primary.main + ")",
            color: "var(--tree-view-color)",
        },
        "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label": {
            backgroundColor: "transparent",
        },
    },
    content: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        "$expanded > &": {
            fontWeight: theme.typography.fontWeightRegular,
        },
    },
    group: {
        marginLeft: theme.spacing(2),
    },
    expanded: {},
    selected: {},
    label: {
        fontWeight: "inherit",
        color: "inherit",
    },
    labelRoot: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0.5, 0),
    },
    folderText: {
        fontWeight: "inherit",
        flexGrow: 1,
        whiteSpace: "nowrap",
    },
    fileText: {
        fontWeight: "inherit",
        flexGrow: 1,
        whiteSpace: "nowrap",
        color: theme.palette.primary.light,
    },
}); });
var LazyFolderContents = function (props) {
    var classes = useTreeItemStyles();
    return (_jsxs(_Fragment, { children: [props.node.folders.map(function (folderPath) {
                return _jsx(LazyFolder, { path: folderPath }, folderPath);
            }),
            props.node.files.map(function (filenameTokens, index) {
                var tokens = filenameTokens.split(":");
                if (tokens.length === 1) {
                    tokens.unshift("");
                }
                var handle = tokens[0];
                var filename = tokens[1];
                var filePath = props.node.path
                    ? props.node.path + "/" + filename
                    : filename;
                return (_jsx(TreeItem, { nodeId: handle + ":" + filePath, label: _jsx("div", __assign({ className: classes.labelRoot }, { children: _jsx(Typography, __assign({ variant: "caption", className: classes.fileText }, { children: filename }), void 0) }), void 0), classes: {
                        root: classes.root,
                        content: classes.content,
                        expanded: classes.expanded,
                        selected: classes.selected,
                        group: classes.group,
                        label: classes.label,
                    } }, index));
            })] }, void 0));
};
var LazyFolder = function (props) {
    var classes = useTreeItemStyles();
    var state = useGetFolderApi(props.path).state;
    return (_jsx(TreeItem, __assign({ nodeId: props.path, label: _jsx("div", __assign({ className: classes.labelRoot }, { children: _jsx(Typography, __assign({ variant: "caption", className: classes.folderText }, { children: props.path.split("/").pop() }), void 0) }), void 0), classes: {
            root: classes.root,
            content: classes.content,
            expanded: classes.expanded,
            selected: classes.selected,
            group: classes.group,
            label: classes.label,
        } }, { children: _jsx(IfExpanded, { children: _jsx(LazyFolderContents, { node: state.data }, void 0) }, void 0) }), props.path));
};
export { LazyFolder, LazyFolderContents };
