var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TreeView from "@material-ui/lab/TreeView";
import axios from "axios";
import React, { useEffect, useRef, useState, } from "react";
import { getDetailsConfig, getFolderConfig, useGetFolderApi, } from "../../../core/libraryService";
import { kProductEmpty } from "../../../core/types";
import { LazyFolderContents } from "./LazyFolders";
var useStyles = makeStyles({
    root: {
        height: 110,
        flexGrow: 1,
        maxWidth: 400,
    },
});
var requestDetails = function (handles, productsRef, setProducts) {
    var extend = {};
    handles.forEach(function (handle) {
        if (productsRef.current[handle] === undefined) {
            var product = kProductEmpty(handle);
            product.request = function () {
                //
                // Request product details
                //
                axios(getDetailsConfig(handle))
                    .then(function (result) {
                    //
                    // Record actual product
                    //
                    var product2 = result.data;
                    var products2 = {};
                    Object.assign(products2, productsRef.current);
                    products2[product2.handle] = product2;
                    productsRef.current = products2;
                    setProducts(productsRef.current);
                })
                    .catch(function () {
                    // swallow the error (probably an undefined product)
                    console.log("Error getting details for " + handle);
                });
            };
            extend[product.handle] = product;
        }
    });
    var products = {};
    Object.assign(products, productsRef.current);
    Object.assign(products, extend);
    productsRef.current = products;
    setProducts(productsRef.current);
};
var requestFolderOrAsset = function (folderOrAssets, productsRef, selectedNodeId, setFolderOrAssets, setSelectedFolderOrAsset, setProducts) {
    axios(getFolderConfig(selectedNodeId)).then(function (result) {
        if (result.status === 200 ||
            result.status === 201 ||
            result.status === 202 ||
            result.status === 304) {
            var receivedFolder = result.data;
            var receivedFolderOrAsset = {
                path: selectedNodeId,
                isFolder: true,
                handle: "",
                childAssets: receivedFolder.files.map(function (tokens) {
                    var _a = tokens.split(":"), handle = _a[0], path = _a[1];
                    return {
                        path: path,
                        isFolder: false,
                        handle: handle,
                        childAssets: [],
                        nestedAssets: [],
                    };
                }),
                nestedAssets: receivedFolder.nestedFiles
                    ? receivedFolder.nestedFiles.map(function (tokens) {
                        var _a = tokens.split(":"), handle = _a[0], path = _a[1];
                        return {
                            path: path,
                            isFolder: false,
                            handle: handle,
                            childAssets: [],
                            nestedAssets: [],
                        };
                    })
                    : [],
            };
            //
            // Add placeholder for folder
            //
            var newFolderOrAssets = __assign({}, folderOrAssets);
            newFolderOrAssets[receivedFolderOrAsset.path] = receivedFolderOrAsset;
            setFolderOrAssets(newFolderOrAssets);
            setSelectedFolderOrAsset([receivedFolderOrAsset]);
            //
            // Requests product details for all folder contents
            //
            var handles_1 = [];
            receivedFolder.files.forEach(function (tokens) {
                var handle = tokens.split(":")[0];
                if (handle) {
                    handles_1.push(handle);
                }
            });
            requestDetails(handles_1, productsRef, setProducts);
        }
    }, function () { });
};
var efxFindOrRequestInfoForSelectedNode = function (folderOrAssets, productsRef, selectedNodeId, setFolderOrAssets, setProducts, setSelectedFolderOrAsset) {
    // note: material-ui tree view is a very new component for
    //       that library, and it currently doesnt actually
    //       support multi-select even though it claims to.
    //       For simplicity of handling the async folder lookup
    //       this code therefore currently only handles the
    //       single select situation.
    var tokens = selectedNodeId.split(":");
    var selectedNodeHandle = tokens.length === 1 ? "" : tokens[0];
    var selectedNodePath = tokens.length === 1 ? tokens[0] : tokens[1];
    if (selectedNodeHandle) {
        var folderOrAsset = {
            path: selectedNodePath,
            isFolder: false,
            handle: selectedNodeHandle,
            childAssets: [],
            nestedAssets: [],
        };
        setSelectedFolderOrAsset([folderOrAsset]);
    }
    else {
        var candidate = folderOrAssets[selectedNodePath];
        if (candidate) {
            setSelectedFolderOrAsset([candidate]);
        }
        else {
            requestFolderOrAsset(folderOrAssets, productsRef, selectedNodeId, setFolderOrAssets, setSelectedFolderOrAsset, setProducts);
        }
    }
};
var efxDispatchSelectedFolderOrAssetChanged = function (setSelectedHandles, products, selectedFolderOrAsset) {
    var handles = [];
    if (selectedFolderOrAsset.length === 1) {
        if (selectedFolderOrAsset[0].isFolder === false) {
            var product = products[selectedFolderOrAsset[0].handle];
            if (product) {
                handles.push(product.handle);
            }
        }
        else {
            var contents = selectedFolderOrAsset[0].childAssets.length > 0
                ? selectedFolderOrAsset[0].childAssets
                : selectedFolderOrAsset[0].nestedAssets;
            contents.forEach(function (folderOrAsset) {
                var product = products[folderOrAsset.handle];
                if (product) {
                    handles.push(product.handle);
                }
            });
        }
    }
    setSelectedHandles(handles);
};
var kRootPath = "";
var FolderTree = function (props) {
    var products = props.products, setProducts = props.setProducts, setSelectedHandles = props.setSelectedHandles;
    var classes = useStyles();
    var _a = useState([]), expanded = _a[0], setExpanded = _a[1];
    var _b = useState("/JF"), selectedNodeId = _b[0], setSelectedNodeId = _b[1];
    var _c = useState({}), folderOrAssets = _c[0], setFolderOrAssets = _c[1];
    var _d = useState([]), selectedFolderOrAsset = _d[0], setSelectedFolderOrAsset = _d[1];
    var productsRef = useRef(props.products);
    useEffect(function () {
        efxFindOrRequestInfoForSelectedNode(folderOrAssets, productsRef, selectedNodeId, setFolderOrAssets, setProducts, setSelectedFolderOrAsset);
    }, [folderOrAssets, productsRef, selectedNodeId, setProducts]);
    useEffect(function () {
        return efxDispatchSelectedFolderOrAssetChanged(setSelectedHandles, products, selectedFolderOrAsset);
    }, [setSelectedHandles, products, selectedFolderOrAsset]);
    var state = useGetFolderApi(kRootPath).state;
    useEffect(function () { }, [state]);
    var handleToggle = function (event, nodeIds) {
        setExpanded(nodeIds);
    };
    var handleSelect = function (event, nodeId) {
        setSelectedNodeId(nodeId);
    };
    return (_jsx(TreeView, __assign({ className: classes.root, defaultCollapseIcon: _jsx(ExpandMoreIcon, {}, void 0), defaultExpandIcon: _jsx(ChevronRightIcon, {}, void 0), expanded: expanded, selected: selectedNodeId, onNodeToggle: handleToggle, onNodeSelect: handleSelect, multiSelect: false }, { children: _jsx(LazyFolderContents, { node: state.data }, void 0) }), void 0));
};
export { FolderTree };
